<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="caz-blocks-sarcho-title">
                        <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">{{ $t('message.payments') }}</div>
                        <div class="block-sarche">
                            <div class="header__search">
                                <el-date-picker
                                    v-model="filterForm.date_interval"
                                    type="daterange"
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    range-separator="/"
                                    :start-placeholder="$t('message.begin_date')"
                                    :end-placeholder="$t('message.end_date')"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :span="12" class="flex-style text-right">
                    <div style="display:flex; float: right;" class="text-right">
                        <div :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                            <el-button size="small" class="mr-2 btu-color" @click="drawerBalance = true">
                                {{ $t("message.payment_from_client_balance") }}
                            </el-button>
                        </div>
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </div>
                    
                </el-col>
            </el-row>
        </div>
        <filter-table v-show="filterOptionsData && filterOptionsData.length > 1" ref="filter_table" @updateActiveFilter="updateActiveFilter" />
        <div v-if="filterOptionsData && filterOptionsData.length == 1" class="w-100 d-flex align-center  justify-center">
            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="mode ? 'price__day' : 'price__night blue__night'">
                    {{ filterOptionsData[0].name }}
                </span>
            </span>

            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="mode ? 'price__day' : 'price__night blue__night'">
                    {{ $t("message.total") }} {{ filterOptionsData[0].total | formatMoney(2) }}
                </span>
            </span>

            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="mode ? 'price__day' : 'price__night blue__night'">
                    {{ $t("message.payments") }}: {{ filterOptionsData[0].count }}
                </span>
            </span>
        </div>
        <div class="card-table-header table-crm-smart custom__scroll">
            <table 
            class="table-my-code table-bordered"
            :class="mode ? 'table__myday' : 'table__mynight'"
            v-loading="loadingData"
            >
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>
                    
                    <th v-if="columns.client_id.show">
                        {{ columns.client_id.title }}
                    </th>

                    <th v-if="columns.filial_id.show">
                        {{ columns.filial_id.title }}
                    </th>
                    
                    <th v-if="columns.bank_account_id.show">
                        {{ columns.bank_account_id.title }}
                    </th>
                    <th v-if="columns.money_amount.show">
                        {{ columns.money_amount.title }}
                    </th>
                    <th v-if="columns.currency.show">
                        {{ columns.currency.title }}
                    </th>
                    <th v-if="columns.currency_rate.show">
                        {{ columns.currency_rate.title }}
                    </th>
                    <th v-if="columns.payment_type_id.show">
                        {{ columns.payment_type_id.title }}
                    </th>   
                    <th v-if="columns.comment.show">
                        {{ columns.comment.title }}
                    </th>

                    <th v-if="columns.date.show">
                        {{ columns.date.title }}
                    </th>
                    <th v-if="columns.created_at.show">
                        {{ columns.created_at.title }}
                    </th>

                    <th v-if="columns.updated_at.show">
                        {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                <th v-if="columns.id.show">
                    <el-input
                        clearable
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.id"
                        :placeholder="columns.id.title"
                        class="id_input"
                    ></el-input>
                </th>

                <th v-if="columns.client_id.show">
                        <select-client
                            :class="mode ? 'input__day' : 'input__night'"
                            :size="'mini'"
                            :placeholder="columns.client_id.title"
                            :id="filterForm.client_id"
                            v-model="filterForm.client_id"
                            >
                        </select-client>
                </th>

                <th v-if="columns.filial_id.show">
                    <select-filial
                        :size="'mini'"
                        :show_all="1"
                        :placeholder="columns.filial_id.title"
                        v-model="filterForm.filial_id"
                    >
                    </select-filial>
                </th>

                <th v-if="columns.bank_account_id.show">
                        <select-bank-account
                            :class="mode ? 'input__day' : 'input__night'"
                            :size="'mini'"
                            :placeholder="columns.bank_account_id.title"
                            :id="filterForm.bank_account_id"
                            v-model="filterForm.bank_account_id"
                            >
                        </select-bank-account>
                </th>

                <th v-if="columns.money_amount.show">
                        <crm-input
                        :placeholder="columns.money_amount.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.money_amount"
                        ></crm-input>
                </th>
                
                <th v-if="columns.currency.show">
                </th>

                <th v-if="columns.currency_rate.show">
                </th>

                <th v-if="columns.payment_type_id.show">
                        <select-payment-type
                            :class="mode ? 'input__day' : 'input__night'"
                            :size="'mini'"
                            :placeholder="columns.payment_type_id.title"
                            :id="filterForm.payment_type_id"
                            v-model="filterForm.payment_type_id"
                            >
                        </select-payment-type>
                </th>

                <th v-if="columns.comment.show">
                    <crm-input
                        :placeholder="columns.comment.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.comment"
                    ></crm-input>
                </th>

                <th v-if="columns.date.show">
                    <el-date-picker
                        :class="mode ? 'input__day' : 'input__night'"
                        size="mini"
                        v-model="filterForm.date"
                        type="datetime"
                        :placeholder="$t('message.date')"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                    ></el-date-picker>
                </th>

                <th v-if="columns.created_at.show">
                    <crm-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                    ></crm-date-picker>
                </th>

                <th v-if="columns.updated_at.show">
                    <crm-date-picker
                        :placeholder="columns.updated_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.updated_at"
                    ></crm-date-picker>
                </th>

                <th
                    class="settinW"
                    v-if="columns.settings.show"
                ></th>
            </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="payment in list" :key="payment.id" class="cursor-pointer">

                    <td v-if="columns.id.show">
                        {{ payment.id }}
                    </td>

                    <td v-if="columns.client_id.show">
                        {{ payment.client ? payment.client.name : '' }}
                    </td>

                    <td v-if="columns.filial_id.show">
                        {{ payment.filial ? payment.filial.name : '' }}
                    </td>

                    <td v-if="columns.bank_account_id.show">
                        {{ payment.bankAccount ? payment.bankAccount.name : '' }}
                    </td>

                    <td v-if="columns.money_amount.show">
                        <span class="bold__money">{{ payment.money_amount | formatNumber(1) }}</span>
                    </td>
                    
                    <td v-if="columns.currency.show">
                        {{ payment.bankAccount ? (payment.bankAccount.currency ? payment.bankAccount.currency.code : '') : '' }}
                    </td>

                    <td v-if="columns.currency_rate.show">
                        {{ payment.currency_rate | formatNumber }}
                    </td>

                    <td v-if="columns.payment_type_id.show">
                        {{ payment.paymentType ? payment.paymentType.name : '' }}
                    </td>
                
                    <td v-if="columns.comment.show">
                        {{ payment.comment }}
                    </td>

                    <td v-if="columns.date.show">
                        {{ payment.date }}
                    </td>

                    <td v-if="columns.created_at.show">
                        {{ payment.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                        {{ payment.updated_at }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                        
                        <crm-settings
                            :name="$options.name"
                            :model="payment"
                            :actions="actions"
                            :permissionOnlyShow="'payments.show'"
                            :permissionShow="'payments.update'"
                            :permissionDestroy="'payments.delete'"
                            @show="show"
                            @edit="edit"
                            @delete="destroyWarning"
                        ></crm-settings>
                    </td>
                </tr>
            </transition-group>
            </table>
            <div class="my___pagination">
            <crm-pagination
                @c-change="updatePagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
                :pagination="pagination"
            ></crm-pagination>
            <!-- <Pagination /> -->
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="80%"
               class="body_scroll_80 bg-se"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerShow"
                size="80%"
                class="body_scroll_80 bg-se"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                @closed="drawerClosed('drawerShowChild')"
            >
                <crm-show
                    :selectedItem="selectedItem"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                ></crm-show>
            </el-drawer> 

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="80%"
                class="body_scroll_80 bg-se"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 

            <el-drawer
                :with-header="false"
                :visible.sync="drawerBalance"
                size="80%"
                class="body_scroll_80 bg-se"
                ref="drawerBalance"
                @opened="drawerOpened('drawerBalanceChild')"
                @closed="drawerClosed('drawerBalanceChild')"
            >
                <crm-payment-balance
                    ref="drawerBalanceChild"
                    drawer="drawerBalance"
                ></crm-payment-balance>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmShow from "./components/crm-show";
import CrmUpdate from "./components/crm-update";
import CrmPaymentBalance from "./components/crm-payment-from-balance";
import { mapGetters, mapActions } from "vuex";
import selectClient from "@/components/inventory/select-client";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";
import filterTable from "./components/filter-table.vue";
import selectFilial from "@/components/inventory/select-filial";

export default {
    name: "payments",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        CrmShow,
        selectClient,
        selectPaymentType,
        selectBankAccount,
        CrmPaymentBalance,
        filterTable,
        selectFilial
    },

    data() {
        return {
            drawerBalance: false
        };
    },

    computed: {
        ...mapGetters({
            list: "payments/list",
            total_of_payments: "payments/total",
            columns: "payments/columns",
            pagination: "payments/pagination",            
            filter: "payments/filter",
            sort: "payments/sort",
            mode: "MODE",
            unpaidList: "deals/unpaidDealsList",
            filterOptionsData: "payments/filterOptions",
        }),
        actions: function () {
            return ["show", "edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "payments/index",
            setPagination: "payments/setPagination",
            updateSort: "payments/updateSort",
            updateFilter: "payments/updateFilter",
            updateColumn: "payments/updateColumn",
            updatePagination: "payments/updatePagination",
            empty: "payments/empty",
            delete: "payments/destroy",
            refreshData: "payments/refreshData",
            updateUnpaidList: "deals/getUnpaidDeals",
            updateFilterOptions: "payments/getFilterOptions",
        }),
        updateActiveFilter(filial_id){
            this.$set(this.filterForm, 'filial_id', filial_id)
        },
        destroyWarning(model){
            this.updateUnpaidList({payment_id: model.id, client_id: model.client_id, get_current: true})
                .then(res =>{
                    if(this.unpaidList.length > 0){
                        this.$confirm(
                            `В этом платеже оплачено ${this.unpaidList.length} сделок, вы действительно хотите удалить все равно ?`,
                            this.$t('message.warning'),
                            {
                            confirmButtonText: this.$t('message.yes'),
                            cancelButtonText: this.$t('message.no'),
                            type: "warning",
                            })
                            .then(() => {
                                this.destroy(model);
                            })
                            .catch(() => {
                            });
                    }else{
                        this.destroy(model);
                    }
                    


                }).catch(err => {

                })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('payments/EMPTY_LIST');
        next()
    },
};
</script>
<style>
    .custom-font-size-16{
        font-size: 16px;
    }
    .bold__money{
        font-weight: bolder;
        font-size: 13px;
    }
</style>
