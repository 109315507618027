<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.payment"),
              })
            }}
            <span class="ml-5"><b> {{$t('message.client_balance')}}: {{ (parseFloat(remainderForClientBalance)+parseFloat(form.balance)) | formatMoney(2) }}</b></span>
            </p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'close'"
                :loading="loadingButton"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      prop="client_id"
                      :label="$t('message.client')"
                      >
                        <select-client
                            :size="'mini'"
                            :disabled="true"
                            :placeholder="columns.client_id.title"
                            :id="form.client_id"
                            :query="{selected_id: form.client_id}" 
                            v-model="form.client_id"
                            >
                        </select-client>
                      </el-form-item> 
                    </el-col>
                    <el-col :span="12">  
                      <el-form-item
                      prop="payment_type_id"
                      :label="$t('message.paymentType')"
                      >
                        <select-payment-type
                            :disabled="true"
                            :size="'mini'"
                            :placeholder="columns.payment_type_id.title"
                            :id="form.payment_type_id"
                            v-model="form.payment_type_id"
                            >
                        </select-payment-type>
                      </el-form-item>   
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="filial_id"
                      :label="$t('message.filial')"
                      >  
                        <select-to-filial
                            :size="'mini'"
                            :show_all="1"
                            :disabled="true"
                            :placeholder="columns.filial_id.title"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                        >
                        </select-to-filial>
                      </el-form-item>   
                    </el-col>

                    <el-col :span="12">  
                      <el-form-item
                      prop="date"
                      :label="$t('message.date')"
                      >
                          <el-date-picker
                              class="w-100"
                              size="medium"
                              v-model="form.date"
                              type="datetime"
                              :disabled="true"
                              :placeholder="$t('message.date')"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                          ></el-date-picker>
                      </el-form-item>   
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                      prop="bank_account_id"
                      :label="$t('message.bankAccount')"
                      >
                          <select-bank-account
                              :disabled="true"
                              :size="'mini'"
                              :clearable="false"
                              :placeholder="columns.bank_account_id.title"
                              :id="form.bank_account_id"
                              v-model="form.bank_account_id"
                              @getAccount="getAccount" 
                              >
                          </select-bank-account>
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4">
                      <el-form-item
                        prop="money_amount"
                        :label="$t('message.money_amount')"
                        >
                          <el-input
                            :disabled="true"
                            :placeholder="$t('message.money_amount')"
                            :size="'medium'"
                            v-model="form.money_amount"
                            type="number"
                            >
                          </el-input>
                      </el-form-item>  
                    </el-col>
                    
                    <el-col :span="4">
                      <el-form-item
                      :label="$t('message.currency')"
                      >
                         {{ bankAccount.currency ?  bankAccount.currency.code : ''}}
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      prop="currency_rate"
                      :label="$t('message.rate')"
                      >
                        <el-input
                          :disabled="true"
                          type="number"
                          size="mini"
                          :placeholder="$t('message.rate')"
                          v-model="form.currency_rate">
                        </el-input>
                      </el-form-item>   
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      :label="$t('message.money_amount')"
                      >
                          <span>{{ form.currency_rate * form.money_amount  | formatMoney(2) }}</span>
                      </el-form-item>   
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="comment"
                      :label="$t('message.comment')"
                      >
                          <el-input
                            :disabled="true"
                            type="textarea"
                            :autosize="{ minRows: 2 }"
                            :placeholder="$t('message.comment')"
                            v-model="form.comment">
                          </el-input>
                      </el-form-item>   
                    </el-col>
                  </el-row>

                    <!-- end-col -->
            </el-form>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div class="m-4">
              <span class="ml-3"><b> {{$t('message.total_amount')}}: {{ total_amount | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.paid_money')}}: {{ total_paid | formatMoney(2) }}</b></span>
              <span class="ml-5" :class="(total_paying > form.money_amount) ? 'danger-insufficient' : ''"><b> {{$t('message.paying_money')}}: {{ total_paying*form.currency_rate | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder')}}: {{ (total_remainder>0 ? total_remainder : 0)| formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder_for_client_balance')}}: {{ (remainderForClientBalance>0 ? remainderForClientBalance : 0)  | formatMoney(2) }}</b></span>
            </div>
            <el-row v-loading="loadingUnpaidDeals || loadingData">
              <el-col :span="24">
                <el-table
                ref="multipleTable"
                :data="selectedDealsList"
                size="small"
                :row-class-name="tableRowClassName"
                style="width: 100%">
                    <el-table-column
                        width="60"
                        :label="$t('message.n')">
                        <template slot-scope="item">
                            {{ item.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.status')">
                        <template slot-scope="item">
                            {{ item.row.status }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.from_filial')">
                        <template slot-scope="item">
                            {{ item.row.from_filial }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.to_filial')">
                        <template slot-scope="item">
                            {{ item.row.to_filial }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.total_price') + ' ' + $t('message.products')">
                        <template slot-scope="item">
                            {{ (item.row.money_amount - item.row.service_sum - item.row.delivery_sum) | formatMoney(2) }}
                        </template>
                    </el-table-column>
						        <el-table-column
                        :label="$t('message.services')">
                        <template slot-scope="item">
                          {{ item.row.service_sum | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.delivery')">
                        <template slot-scope="item">
                            {{ item.row.delivery_sum | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.total_amount')">
                        <template slot-scope="item">
                            {{ (parseFloat(item.row.money_amount)) | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.paid_money')">
                        <template slot-scope="item">
                            {{ item.row.paid_money | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="180"
                        :label="$t('message.paying_money')">
                        <template slot-scope="item">
                          <el-input
                            :disabled="true"
                            :placeholder="$t('message.please_enter_input', {input:$t('message.money_amount')})"
                            :size="'medium'"
                            v-model="item.row.current_payment"
                            type="number"
                            >
                          </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.remainder')">
                        <template slot-scope="item">
                            {{ calculate_remainder(item.row) | formatMoney(2) }}
                        </template>
                    </el-table-column>
                </el-table>
              </el-col>
            </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectClient from "@/components/inventory/select-client";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
  mixins: [form, drawer, show],
  components: {
    selectClient,
    selectPaymentType,
    selectBankAccount,
    selectToFilial
  },
  data() {
    return {
      bankAccount: {},
      drawerUnpaidDeals: false,
      loadingUnpaidDeals: false,
      selectedDealsList: [],
      autoCalculate: false,
      paymentRemainder: 0
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          authUser: "auth/user",
          rules: "payments/rules",
          model: "payments/model",
          columns: "payments/columns",
          unpaidList: "deals/unpaidDealsList",
      }),
      total_remainder(){
          return _.round(parseFloat((this.total_amount - this.total_paid - ((this.form.money_amount-this.paymentRemainder)*this.form.currency_rate))), 9);
      },
      total_amount(){
        if(this.selectedDealsList.length > 0){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.money_amount) || 0), 0)); 
        }
        return 0;
      },
      total_paid(){
        if(this.selectedDealsList.length > 0){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.paid_money) || 0), 0)); 
        }
        return 0;
      },
      total_paying(){
        if(this.selectedDealsList.length > 0){
          return _.round(parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.current_payment) || 0), 0)), 9); 
        }
        return 0;
      },
      remainderForClientBalance(){
          return _.round((this.form.money_amount - this.total_paying), 9)*this.form.currency_rate; 
      }
  },
  watch: {
      bankAccount: {
          handler: function(newVal, oldVal) {
              if(this.bankAccount.currency && oldVal.currency){
                this.form.currency_rate = this.bankAccount.currency.rate;
              }
          },
          deep: true,
          immediate: true
      },
      "form.money_amount": {
          handler: function(newVal, oldVal) {
            if(newVal > 1){
              this.paymentsByDeals(this.selectedDealsList);
            }
          },
          deep: true,
          immediate: true
      },
      "form.currency_rate": {
          handler: function(newVal, oldVal) {
            if(this.form.money_amount > 1){
              this.paymentsByDeals(this.selectedDealsList);
            }
          },
          deep: true,
          immediate: true
      },
  },
  methods: {
      ...mapActions({
          save: "payments/update",
          empty: "payments/empty", 
          show: "payments/show",
          updateUnpaidList: "deals/getUnpaidDeals",
      }),

      fetchData() {
          if (!this.loadingData) {  
              this.loadingData = true;      
              this.show(this.selectedItem.id).then(res => {
                  this.loadingData = false;
                  // if(!this.form.user_id){
                  //   this.$set(this.form, 'user_id', this.authUser.id)
                  // }
                  this.updateUnpaidList({payment_id: this.form.id, client_id: this.form.client_id, get_current: true, only_paid_deals: true})
                    .then(resp =>{
                      this.selectedDealsList = JSON.parse(JSON.stringify(this.unpaidList));
                      
                      this.$refs.multipleTable.toggleAllSelection(this.selectedDealsList, true);
                    });
              }).catch(err => {
                  this.loadingData = false
              });
          }
      },
      
      tableRowClassName({row, rowIndex}){
        let remainder = _.round(this.calculate_remainder(row), 2);
        if(remainder < 0){
          return 'danger-row';
        }
        if(remainder === 0){
          return 'success-row';
        }
        return '';
      },
      calculate_remainder(item){
        let unpaid = _.round(parseFloat(item.money_amount - item.paid_money), 9)
        if(item.current_payment && item.current_payment > 0){
          unpaid = _.round(parseFloat(unpaid - (item.current_payment*this.form.currency_rate)), 9)
        }
        return _.round(parseFloat(unpaid), 9);
      },
      checkClient(){
        if(this.form.client_id){
          this.loadingUnpaidDeals = true;
          this.updateUnpaidList({payment_id: this.form.id, client_id: this.form.client_id})
            .then(res =>{
              this.loadingUnpaidDeals = false;
              this.unpaidList.forEach(element => {
                if(this.selectedDealsList.some(e => e.id === element.id)){
                }else{
                  let deal = JSON.parse(JSON.stringify(element));
                  this.$set(deal, 'current_payment', 0);
                  this.selectedDealsList.push(deal);
                }
              });
            }).catch(err => {
              this.loadingUnpaidDeals = false;
            });
        }else{
          this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: this.$t('message.please_select_input', {input: this.$t('message.client')})
          });
        }
      },

      paymentsByDeals(selectedList, renew_remainder = true){
        if(this.autoCalculate){
          if(renew_remainder){
            this.paymentRemainder = this.form.money_amount;
          }
          selectedList.forEach(element => {
            if(this.paymentRemainder > 0){
              this.$set(element, 'current_payment', 0);
              let current_payment = 0
              let total_amount_in_deal_currency = parseFloat(this.paymentRemainder * this.form.currency_rate);
              let total_unpaid = _.round(parseFloat(parseFloat(element.money_amount) - parseFloat(element.paid_money)), 9);
              if(total_amount_in_deal_currency >= total_unpaid){
                this.paymentRemainder -= _.round(parseFloat(total_unpaid/this.form.currency_rate), 9);
                current_payment = _.round(parseFloat(total_unpaid/this.form.currency_rate), 9);
              }else{
                current_payment = _.round(parseFloat(this.paymentRemainder), 9);
                this.paymentRemainder = 0;
              }
              this.$set(element, 'current_payment', current_payment);
              setTimeout(() => {
                this.$refs.multipleTable.toggleRowSelection(element, true);
              }, 100);
            }else{
              this.$set(element, 'current_payment', 0);
            }
          });
        }
      },

      getAccount(account){
        this.bankAccount = JSON.parse(JSON.stringify(account));
      },
      afterLeave(){
        this.paymentRemainder = 0;
        this.autoCalculate = false;
        this.selectedClient = {};
        this.bankAccount = {};
        this.empty();
        this.selectedDealsList = [];
      },
      removeFromPaid(index, deal){
        this.selectedDealsList.splice(index, 1);
      },
      beforeSubmit(){
        let success = true;
        for(const deal of this.selectedDealsList){
          if(_.round((deal.money_amount - deal.current_payment*this.form.currency_rate), 9) < 0 || deal.current_payment < 0){
            success = false;
            break;
          }
        }
      
        let paymentsForDeals = this.selectedDealsList.map(el => { return {deal_id:el.id, current_payment:el.current_payment }});
        paymentsForDeals = paymentsForDeals.filter(el => el.current_payment > 0);
        this.form.paymentsForDeals = paymentsForDeals;
        if(success && this.total_paying <= this.form.money_amount && this.form.money_amount > 0){
          this.submit();
        }else if(!success){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.incorrect_money_for_deal')
          });
        }else if(this.total_paying > this.form.money_amount){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.insufficient_money')
          });
        }else if(this.form.money_amount <= 0){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.enter_money_correctly')
          });
        }
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          if(this.remainderForClientBalance > 0){
                            this.updateFreeClients();
                          }
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>

<style>
.el-table .warning-row {
    background: rgb(245, 243, 142);
  }

  .el-table .success-row {
    background: #99da76;
  }
  .el-table .danger-row {
    background: #e9a5a9;
  }
  .danger-insufficient {
    color: #e6757c;
  }

  
</style>